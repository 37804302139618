import * as React from 'react';
import {useEffect, useState} from 'react';
import {Col, Divider, Row, Select} from 'antd';
import {SelectValue} from 'antd/lib/select';
import {
    useClientId,
    useOrganizationId,
    useSchoolYearId,
    useSelectClient,
    useSelectOrganization,
    useSelectSchoolYear,
    useUser
} from "../../../Store/UserStore";
import {ClientDesc, EnrollmentPeriod, Organization} from "../../../Model/enrollmentModel";
import ShadowedPageHeader from "../../../Components/Styled/ShadowedPageHeader";

const {Option} = Select;

function getOrganizationOptions(organizations) {
    var list = organizations?.map(function (org: Organization) {
        return <Option key={org.organizationId} value={org.organizationId}>{org.name}</Option>;
    });

    return list;
}

function getClientOptions(clients) {
    var list = clients?.map(function (client: ClientDesc) {
        return <Option key={client.clientId} value={client.clientId}>{client.schoolName}</Option>;
    });

    return list;
}

function getEnrollmentPeriodOptions(enrollmentPeriods: EnrollmentPeriod[]) {
    console.log(`getEnrollmentPeriodOptions enrollmentPeriods:${JSON.stringify(enrollmentPeriods)}`)

    var list = enrollmentPeriods?.filter((enrollmentPeriod) => {
        return !enrollmentPeriod.isShadowNextPeriod;
    }).map(function (enrollmentPeriod: EnrollmentPeriod) {
        if (enrollmentPeriod.isCurrentEnrollmentPeriod) {
            return <Option key={enrollmentPeriod.id}
                           value={enrollmentPeriod.id}>{enrollmentPeriod.name} (Current)</Option>;
        } else {
            return <Option key={enrollmentPeriod.id} value={enrollmentPeriod.id}>{enrollmentPeriod.name}</Option>;
        }
    });

    return list;
}


function MainHeaderContainer() {
    let user = useUser();
    let selectOrganization = useSelectOrganization();
    let selectClient = useSelectClient();
    let selectSchoolYear = useSelectSchoolYear();
    let organizationId = useOrganizationId();
    let clientId = useClientId();
    let enrollmentPeriodId = useSchoolYearId();
    const [organizationList, setOrganizationList] = useState<any>()
    const [clientsList, setClientsList] = useState<any>()
    const [enrollmentPeriodList, setEnrollmentPeriodList] = useState<any>()

    //Organization
    useEffect(() => {
        setOrganizationList(getOrganizationOptions(user?.authorizedOrganizations));
    }, [user?.authorizedOrganizations])

    useEffect(() => {
        setClientsList(getClientOptions(user?.organization?.clients));
    }, [user?.organization?.clients])

    useEffect(() => {
        setEnrollmentPeriodList(getEnrollmentPeriodOptions(user?.clientConfig?.enrollmentPeriodConfig?.enrollmentPeriods))
    }, [user?.clientConfig?.enrollmentPeriodConfig?.enrollmentPeriods])

    let onOrganizationChange = (value: SelectValue): void => {
        console.log(`onOrganizationChange:${value.toString()}`)
        selectOrganization(value.toString());
    }
    let onClientChange = (value: SelectValue): void => {
        console.log(`onClientChange:${value.toString()}`)
        selectClient(value.toString());
    }
    let onSchoolYearChange = (value: SelectValue): void => {
        console.log(`onSchoolYearChange:${value.toString()}`)
        selectSchoolYear(value.toString());
    }

    return (
        <ShadowedPageHeader
            ghost={true}
            title={user?.clientConfig?.schoolName ?? ""}
            //subTitle={user?.clientConfig?.schoolName ?? ""}
            extra={[
                <Row key="contextSelect">
                    {user.isAdmin && <Col>
                        <Select key="selectOrganization"
                                // defaultValue={user?.organization?.organizationId}
                                value={organizationId}
                                style={{width: 200}}
                                onChange={onOrganizationChange}>
                            {organizationList}
                        </Select>
                    </Col>}
                    <Divider type="vertical"/>
                    <Col>
                        <Select key="selectClient"
                                // defaultValue={user?.clientConfig?.clientId}
                                value={clientId}
                                style={{width: 200}}
                                onChange={onClientChange}>
                            {clientsList}
                        </Select>
                    </Col>
                    <Divider type="vertical"/>
                    <Col>
                        <Select key="selectEnrollmentPeriod"
                                // defaultValue={enrollmentPeriodId}
                                value={enrollmentPeriodId}
                                style={{width: 200}}
                                onChange={onSchoolYearChange}>
                            {enrollmentPeriodList}
                        </Select>
                    </Col>


                </Row>
            ]}
        />
    );
}

export default MainHeaderContainer;